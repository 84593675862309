import { FilterUid } from 'src/components/organisms/Filter/constants/uids';
import type { BrowseDataAPIFilter } from 'src/services/browse-data-api/types/BrowseDataAPIFilter';
import type { BrowseDataAPISortingOption } from 'src/services/browse-data-api/types/BrowseDataAPISortingOption';

export const getFiltersWithSortFilter = (
  filters: BrowseDataAPIFilter[],
  sortingOptions: BrowseDataAPISortingOption[],
) => {
  const sortFilter: BrowseDataAPIFilter = {
    active: false,
    options: sortingOptions.map(({ active, id, title }) => ({
      uid: id,
      value: id,
      title,
      active,
      activeCount: null,
    })),
    expandedByDefault: false,
    title: 'Sort',
    type: 'radio',
    uid: FilterUid.Sort,
  };

  return [sortFilter, ...filters];
};
