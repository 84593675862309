import { getDeliveryByEventLabel, getProductIdFromProductCode } from '@noths/polaris-client-utils';
import type { BaseFieldObject, Price, RecommendedProduct } from '@noths/polaris-dev-ts-types';

import type { ReduxProductsCategory } from 'src/redux/types/category';
import type { BrowseDataAPIProduct } from 'src/services/browse-data-api/types/BrowseDataAPIProduct';
import type { ImpressionFieldObject, ProductFieldObject } from 'src/types/googleAnalytics';
import { PageType } from 'src/types/navigation';
import { isCategoryPageGuard, isPartnerPageGuard, isSearchPageGuard } from 'src/utils/typeGuards';

type FieldObjectType<P extends PageType> = ProductFieldObject<P> | ImpressionFieldObject<P>;

const getPriceInGBP = (prices: Price[]) => {
  const priceInGBP = prices.find(({ currency }) => currency === 'GBP');

  return priceInGBP?.amount
    ? new Intl.NumberFormat('en-GB', {
        style: 'decimal',
        minimumFractionDigits: 2,
      }).format(priceInGBP.amount / 100)
    : '';
};

const generateFieldObject = (
  product: BrowseDataAPIProduct,
  items: Record<number, BrowseDataAPIProduct[]>,
  productsReturnedCount: number,
): BaseFieldObject => {
  const {
    code,
    deliveryByEvent,
    freeDomesticDelivery,
    isNew,
    onSale,
    partner,
    price,
    purchasable,
    salePercentage,
    title,
  } = product;
  const findProductByCode = (prod: BrowseDataAPIProduct) => prod.code === product.code;
  const productsArr = Object.values(items).flat();
  const pageProductPosition =
    (productsArr.findIndex(findProductByCode) % productsReturnedCount) + 1;

  const findProductWithinPage = (pageNumber: string) => items[pageNumber].find(findProductByCode);
  const pages = Object.keys(items);
  const pageNumber = Number(pages.find(findProductWithinPage));

  /*
    Use page product position if on the 1st page.
    Otherwise, count number of products spanning all previous pages,
    and add page product position to said number.
  */
  const dimension25 =
    pageNumber === 1
      ? pageProductPosition
      : pageProductPosition + (pageNumber - 1) * productsReturnedCount;

  const dimension22List: string[] = [
    freeDomesticDelivery && 'free uk delivery',
    isNew && 'new',
    onSale && !!salePercentage && `-${salePercentage}% off`,
    !purchasable && 'out of stock',
    deliveryByEvent?.arriveByEvent && getDeliveryByEventLabel(deliveryByEvent.eventName),
  ].filter(Boolean) as string[];

  return {
    brand: partner.name,
    dimension22: dimension22List.join(','),
    dimension25: dimension25,
    dimension30: String(code),
    dimension31: String(price.amount / 100),
    dimension32: String(price.amount / 100),
    dimension33: onSale ? `${salePercentage}.0%` : 'none',
    dimension34: partner.shortcode,
    id: String(getProductIdFromProductCode(code)),
    name: title,
    position: pageProductPosition,
    price: String(price.amount / 100),
  };
};

const getDimension16PartialFieldObject = (
  fieldObject: Partial<BaseFieldObject>,
  product: BrowseDataAPIProduct,
  category: ReduxProductsCategory | null,
  pageType: PageType | null,
) => {
  const fieldObj: Partial<BaseFieldObject> = {};

  if (isCategoryPageGuard<FieldObjectType<PageType.Category>>(pageType!, fieldObject)) {
    fieldObj.dimension16 = String(category!.id);
  } else if (isPartnerPageGuard<FieldObjectType<PageType.Partner>>(pageType!, fieldObject)) {
    fieldObj.dimension16 = product.partner.name.toLowerCase();
  } else if (isSearchPageGuard<FieldObjectType<PageType.Search>>(pageType!, fieldObject)) {
    fieldObj.dimension16 = '15000';
  }

  return fieldObj;
};

export const getProductFieldObject = <T extends PageType>(
  product: BrowseDataAPIProduct,
  items: Record<number, BrowseDataAPIProduct[]>,
  productsReturnedCount: number,
  category: ReduxProductsCategory | null,
  pageType: PageType | null,
): ProductFieldObject<T> => {
  const fieldObject = generateFieldObject(product, items, productsReturnedCount);

  const dimension16PartialFieldObject = getDimension16PartialFieldObject(
    fieldObject,
    product,
    category,
    pageType,
  ) as ProductFieldObject<T>;

  return {
    ...fieldObject,
    ...dimension16PartialFieldObject,
  } as ProductFieldObject<T>;
};

export const getFieldObjectList = (
  product: BrowseDataAPIProduct,
  category: ReduxProductsCategory | null,
  searchTerm: string,
  pageType: PageType | null,
  listSuffix?: ` - ${string}`,
) => {
  switch (pageType) {
    case PageType.Category:
      return `${PageType.Category} - ${category!.name}${listSuffix || ''}`;
    case PageType.Partner:
      return `${PageType.Partner} - ${product.partner.name.toLowerCase()}${listSuffix || ''}`;
    case PageType.Search:
      return `${PageType.Search} - ${searchTerm}${listSuffix || ''}`;
    case PageType.AllProducts:
      return `${PageType.Category} - all products`;
    default:
      return '';
  }
};

export const getImpressionFieldObject = <T extends PageType>(
  product: BrowseDataAPIProduct,
  items: Record<number, BrowseDataAPIProduct[]>,
  productsReturnedCount: number,
  category: ReduxProductsCategory | null,
  searchTerm: string,
  pageType: PageType | null,
  {
    listSuffix,
  }: {
    listSuffix?: ` - ${string}`;
  } = {},
) => {
  const impressionFieldObject = {
    ...generateFieldObject(product, items, productsReturnedCount),
    list: getFieldObjectList(product, category, searchTerm, pageType, listSuffix),
  };

  const dimension16PartialFieldObject = getDimension16PartialFieldObject(
    impressionFieldObject,
    product,
    category,
    pageType,
  ) as ImpressionFieldObject<T>;

  return {
    ...impressionFieldObject,
    ...dimension16PartialFieldObject,
  } as ImpressionFieldObject<T>;
};

interface GenerateProductsEventParams {
  placementName?: string;
  placementStrategy?: string;
  positionInList?: number;
  products: RecommendedProduct[];
}

export const generateProductsForEvent = ({
  placementName,
  placementStrategy,
  positionInList,
  products,
}: GenerateProductsEventParams): BaseFieldObject[] =>
  products.map(
    (
      {
        code,
        free_domestic_delivery,
        new: isNew,
        on_sale,
        partner,
        prices,
        purchasable,
        sale_percentage,
        title,
      },
      index,
    ) => {
      const dimension22List = [
        free_domestic_delivery && 'free uk delivery',
        isNew && 'new',
        on_sale && !!sale_percentage && `-${sale_percentage}% off`,
        !purchasable && 'out of stock',
      ]
        .filter(Boolean)
        .join(', ');

      const price = getPriceInGBP(prices);
      const position = positionInList || index + 1;
      const list = placementStrategy
        ? `product - rich relevance - ${placementName} - ${placementStrategy}`
        : `product - rich relevance - ${placementName}`;

      const baseFieldObject: BaseFieldObject = {
        name: title,
        id: String(getProductIdFromProductCode(code)),
        dimension22: dimension22List,
        dimension25: position,
        dimension30: String(code),
        dimension31: price,
        price: price,
        dimension32: price,
        dimension33: on_sale ? `${sale_percentage}.0%` : 'none',
        brand: partner.name,
        dimension34: partner.shortcode,
        position: position,
        list,
        dimension16: 'not set',
      };

      return baseFieldObject;
    },
  );
