import { selectRouterPathname } from '@noths/polaris-client-next-redux';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { replace } from 'connected-next-router';
import qs from 'querystring';

import { PRODUCT_CONTAINER_WRAPPER } from 'src/constants/elementIds';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { selectRequestOptionsAsQueryObject } from 'src/redux/products/selectors';
import { requestProducts, requestProductsIsRejected } from './requestProducts';

export enum UpdateFiltersActionType {
  FILTER = 'FILTER',
  SORT = 'SORT',
}

export const updateFilters = createAsyncThunk(
  'products/updateFilters',
  async (filterType: UpdateFiltersActionType, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as ReduxApplicationState;
    const {
      filter: {
        collections: { lastUpdatedFilter },
      },
      router: {
        location: { pathname },
      },
    } = state;

    const queryObject = selectRequestOptionsAsQueryObject(state);

    const query = qs.stringify(queryObject);

    dispatch(
      replace(
        {
          pathname: selectRouterPathname(state),
          query,
        },
        query ? `${pathname}?${query}` : pathname,
        { shallow: true, scroll: false },
      ),
    );

    if (lastUpdatedFilter) {
      const { filterOptionActive, filterOptionValue, filterUid } = lastUpdatedFilter;

      queryObject.updatedFilter = filterUid;
      queryObject.updatedFilterOption = filterOptionValue;
      queryObject.updatedFilterAction = filterOptionActive ? 'selected' : 'deselected';
    }

    const action = await dispatch(
      requestProducts({
        query: {
          ...queryObject,
          path: pathname,
        },
      }),
    );

    if (requestProductsIsRejected(action)) {
      return rejectWithValue('There was a problem loading products');
    }

    const productContainerOffsetTop = document.getElementById(PRODUCT_CONTAINER_WRAPPER)?.offsetTop;
    window.scrollTo({ top: productContainerOffsetTop, behavior: 'auto' });

    return filterType;
  },
);
