import { createSlice } from '@reduxjs/toolkit';

interface UserState {
  cognitoID: string | null;
}

const initialState: UserState = {
  cognitoID: null,
};

export const { reducer } = createSlice({
  name: 'user',
  initialState,
  reducers: {},
});
