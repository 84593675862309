import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { FilterNavType, PageType } from 'src/types/navigation';

export interface NavigationState {
  pageType: PageType | null;
  pathname: string;
}

interface SetPathPayload {
  host: string;
  url: string;
}

export const initialState: Readonly<NavigationState> = { pageType: null, pathname: '' };

export const { actions, reducer } = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setInitialPageLoad: (_state: Record<never, never>, _action: PayloadAction<FilterNavType>) => {},
    setPathname: (state, { payload: request }: PayloadAction<SetPathPayload>) => {
      const url = new URL(request.url || '', `http://${request.host}`);
      state.pathname = url.pathname;
    },
  },
});
