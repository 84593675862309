import type { BaseFieldObject } from '@noths/polaris-dev-ts-types';

type EventCategory = 'Rich Relevance' | 'Product listings';

export type ProductClickTrackingEvent = {
  algonomy_tracking_URL?: string;
  destination_URL?: string;
  ecommerce: {
    click: {
      actionField: {
        list: string;
      };
      products: BaseFieldObject[];
    };
  };
  event: 'product_clicks';
  event_action: 'Product Click';
  event_category: EventCategory;
  event_label: string;
};

interface GetProductClickTrackingEventArgs {
  actionFieldList: string;
  destinationUrl?: string;
  eventCategory: EventCategory;
  eventLabel: string;
  products: BaseFieldObject[];
  recommendationsTrackingUrl?: string;
}

export const getProductClickTrackingEvent = ({
  actionFieldList,
  destinationUrl,
  eventCategory,
  eventLabel,
  products,
  recommendationsTrackingUrl,
}: GetProductClickTrackingEventArgs): ProductClickTrackingEvent => {
  return {
    event: 'product_clicks',
    event_category: eventCategory,
    event_action: 'Product Click',
    event_label: eventLabel,
    ...(destinationUrl ? { destination_URL: destinationUrl } : {}),
    ...(recommendationsTrackingUrl ? { algonomy_tracking_URL: recommendationsTrackingUrl } : {}),
    ecommerce: {
      click: {
        actionField: {
          list: actionFieldList,
        },
        products,
      },
    },
  };
};
