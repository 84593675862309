import { combineReducers } from '@reduxjs/toolkit';

import { actions as filterActions, reducer as filterReducer } from './filter.slice';
import { actions as filterModalActions, reducer as filterModalReducer } from './filterModal.slice';

export const actions = {
  ...filterActions,
  ...filterModalActions,
};

export const reducer = combineReducers({
  collections: filterReducer,
  filterModal: filterModalReducer,
});
