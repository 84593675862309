import { createMiddlewareFromEventsMap } from '@noths/polaris-client-next-redux';

import { GAEventsMap as productGAEventsMap } from 'src/components/organisms/ProductList/tracking/eventsMap';
import { GAEventsMap as favouritesGAEventsMap } from 'src/redux/favourites/tracking/eventsMap';
import type { ReduxApplicationState } from './combinedReducer';
import { GAEventsMap as productsGAEventsMap } from './products/tracking/eventsMap';

export const eventsMap = {
  ...favouritesGAEventsMap,
  ...productsGAEventsMap,
  ...productGAEventsMap,
};

export const GAMiddleware = createMiddlewareFromEventsMap<ReduxApplicationState>(eventsMap);
