import type { BrowseDataAPIProduct } from 'src/services/browse-data-api/types/BrowseDataAPIProduct';

export const getProductPageStartAndEndIndex = (
  perPage: number,
  currentPage: number,
  currentPageProductCount: number,
) => {
  const previousPagesProductCount = currentPage > 1 ? perPage * (currentPage - 1) : 0;

  return {
    start: previousPagesProductCount + 1,
    end: previousPagesProductCount + currentPageProductCount,
  };
};

interface ProductsByPage {
  [key: number]: BrowseDataAPIProduct[];
}

export const getPageNumbers = (productsByPage: ProductsByPage) => {
  return Object.keys(productsByPage)
    .map((pageNo) => parseInt(pageNo))
    .sort((a, b) => a - b);
};

export const getProductCountUpToEndOfPage = (
  productsByPage: ProductsByPage,
  currentPage: number,
) => {
  return getPageNumbers(productsByPage).reduce((acc, pageNumber) => {
    if (pageNumber <= currentPage) {
      return acc + productsByPage[pageNumber].length;
    }
    return acc;
  }, 0);
};
