import type { SerializedError } from '@reduxjs/toolkit';

export class ThunkSerializableError extends Error implements SerializedError {
  constructor(message: string, name: string) {
    super(message);
    this.name = name;
    this.message = message;
  }
}

export const CATEGORY_NOT_FOUND_ERROR = new ThunkSerializableError(
  'The requested category was not found',
  'CATEGORY_NOT_FOUND',
);

export const PARTNER_NOT_FOUND_ERROR = new ThunkSerializableError(
  'The requested partner was not found',
  'PARTNER_NOT_FOUND',
);
