export enum Placement {
  CategoryListingsRecentlyViewed = 'category_page.recently_viewed',
  GenericPageRecentlyViewed = 'generic_page.recently_viewed',
  OutOfStock = 'item_page.OOS',
  PartnerListingsRecentlyViewed = 'brand_page.recently_viewed',
  SearchListingsRecentlyViewed = 'search_page.recently_viewed',
}

// Note: products null when request fails or when 0 products returned
export type PlacementEntry<T> = {
  placementStrategy?: string;
  placementTitle?: string;
  products: T[] | null;
};

export type PlacementType<T> = Record<Placement, PlacementEntry<T>>;
