import { selectRouterPathname } from '@noths/polaris-client-next-redux';
import { createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';
import { replace } from 'connected-next-router';
import qs from 'querystring';

import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { selectCurrentPage, selectRequestOptionsAsQueryObject } from 'src/redux/products/selectors';
import { TriggerName } from 'src/redux/products/types';
import { requestProducts } from './requestProducts';

export const loadPageOfProducts = createAsyncThunk(
  'products/loadPageOfProducts',
  async (pageNumber: number, { dispatch, getState }) => {
    const state = getState() as ReduxApplicationState;
    const {
      router: {
        location: { pathname },
      },
    } = state;

    const queryObject = {
      ...selectRequestOptionsAsQueryObject(state),
      page: pageNumber,
    };

    if (pageNumber > selectCurrentPage(state)) {
      const query = qs.stringify(queryObject);

      dispatch(
        replace(
          {
            pathname: selectRouterPathname(state),
            query,
          },
          `${pathname}?${query}`,
          { shallow: true, scroll: false },
        ),
      );
    }

    return await dispatch(
      requestProducts({
        query: {
          ...queryObject,
          path: pathname,
        },
        replaceProducts: false,
        triggerName:
          pageNumber > selectCurrentPage(state)
            ? TriggerName.LOAD_MORE_PRODUCTS
            : TriggerName.LOAD_PREVIOUS_PRODUCTS,
      }),
    ).then(unwrapResult);
  },
);
