import type { ReduxProductsCategory } from 'src/redux/types/category';

const defaults: Readonly<ReduxProductsCategory> = {
  canonicalTag: '',
  description: '',
  heading: '',
  hierarchy: [],
  seoCategories: [],
  id: 0,
  indexable: true,
  metaTagDescription: '',
  name: '',
  pageTitle: '',
  pageTitleOverride: null,
  partnerImage: null,
  baseType: null,
};

export const reduxProductsCategoryBuilder = (category?: Partial<ReduxProductsCategory>) => ({
  ...defaults,
  ...category,
});
