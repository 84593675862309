import { toSentenceCase } from '@noths/polaris-client-utils';
import { createSelector } from '@reduxjs/toolkit';

import type { ReduxApplicationState } from 'src/redux/combinedReducer';

const selectSortingOptions = (state: ReduxApplicationState) => state.filterMenu.sortingOptions;

export const selectSelectedSortingOption = (state: ReduxApplicationState) =>
  state.filterMenu.selectedSortOption;

export const selectSelectedSortingOptionInSentenceCase = createSelector(
  [selectSelectedSortingOption],
  (selectedSortingOption) => {
    return selectedSortingOption
      ? {
          ...selectedSortingOption,
          title: toSentenceCase(selectedSortingOption.title),
        }
      : null;
  },
);

export const selectSortingOptionsInSentenceCase = createSelector(
  [selectSortingOptions],
  (sortingOptions) =>
    sortingOptions.map(({ title, ...option }) => ({
      ...option,
      title: toSentenceCase(title),
    })),
);
