import type {
  RichRelevancePlacement,
  RichRelevancePlacementItem,
} from '@noths/polaris-client-recommendations';
import {
  addRUMTiming,
  fetchJSONWithTimeout,
  getProductIdFromProductCode,
} from '@noths/polaris-client-utils';
import type {
  RecommendationsServiceProduct,
  RecommendedProduct,
} from '@noths/polaris-dev-ts-types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import getConfig from 'next/config';

import type { Placement } from 'src/constants/recommendations';
import type { NextConfig } from 'src/types/nextConfig';
import { logger } from 'src/utils/serverLogger';
import type { ProductPlacementRecord, ProductPlacementRecordError } from './types';

const { endpoints } = (getConfig() as NextConfig).publicRuntimeConfig;

const transformToRecommendedProduct = (
  products: RecommendationsServiceProduct[],
  placementItems: RichRelevancePlacementItem[],
): RecommendedProduct[] =>
  products.map((product) => {
    const placementItem = placementItems.find(
      ({ id }) => getProductIdFromProductCode(product.code) === Number(id),
    );

    return {
      ...product,
      linkURL: placementItem?.linkURL ?? '',
    };
  });

export const requestRecommendedProducts = createAsyncThunk<
  ProductPlacementRecord | null,
  RichRelevancePlacement,
  {
    rejectValue: ProductPlacementRecordError;
  }
>(
  'richRelevance/requestRecommendedProducts',
  async ({ items = [], message, placement_name, strategy }, thunkApi) => {
    const ids = items.map((p) => p.id).join('&ids[]=');
    const url = `${endpoints.recommendedProducts}?ids[]=${ids}`;

    try {
      const response = await fetchJSONWithTimeout(url, { timeout: 2000 });
      const { products }: Record<'products', RecommendationsServiceProduct[]> =
        await response.json();

      const recommendedProducts = transformToRecommendedProduct(products, items);

      return {
        products: recommendedProducts,
        placementType: placement_name as Placement,
        placementTitle: message,
        placementStrategy: strategy,
      };
    } catch (err) {
      const error = err as Error;

      logger.error({
        location: 'requestRecommendedProducts',
        extra: { description: `Exception thrown during fetch of recommended products data`, error },
      });

      addRUMTiming('recommendationsError');

      return thunkApi.rejectWithValue({
        error: { message: error.message, stack: error.stack, name: error.name },
        placementType: placement_name,
      } as ProductPlacementRecordError);
    }
  },
);
