/* istanbul ignore file */

import type { MutableRefObject } from 'react';
import type { ForwardRefType } from '@noths/polaris-dev-ts-recipes';

import type { PageType } from 'src/types/navigation';
import { isCategoryPage, isPartnerPage, isSearchPage } from './page';

export function isMutableRefObject<T>(ref: ForwardRefType<T>): ref is MutableRefObject<T> {
  if (ref && 'current' in ref) {
    return true;
  }

  return false;
}

export const isCategoryPageGuard = <T>(pageType: PageType, value: unknown): value is T =>
  isCategoryPage(pageType);

export const isPartnerPageGuard = <T>(pageType: PageType, value: unknown): value is T =>
  isPartnerPage(pageType);

export const isSearchPageGuard = <T>(pageType: PageType, value: unknown): value is T =>
  isSearchPage(pageType);
