import type { NavigationState } from 'src/redux/navigation/slice';
import { PageType } from 'src/types/navigation';

export const isCategoryPage = (pageType: NavigationState['pageType']) =>
  pageType === PageType.Category;

export const isPartnerPage = (pageType: NavigationState['pageType']) =>
  pageType === PageType.Partner;

export const isSearchPage = (pageType: NavigationState['pageType']) => pageType === PageType.Search;
