import { createSelector } from '@reduxjs/toolkit';

import { FilterUid } from 'src/components/organisms/Filter/constants/uids';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import type { BrowseDataAPIFilter } from 'src/services/browse-data-api/types/BrowseDataAPIFilter';

export const selectFilters = (state: ReduxApplicationState) => state.filter.collections.filters;

export const selectFilterByUid = (
  state: ReduxApplicationState,
  uid: string,
): BrowseDataAPIFilter | null => {
  return state.filter.collections.filters.find((filter) => filter.uid === uid) || null;
};

export const selectFiltersWithoutSortFilter = createSelector(
  [selectFilters],
  (filters): BrowseDataAPIFilter[] => filters.filter((filter) => filter.uid != FilterUid.Sort),
);

export const selectTotalActiveOptions = createSelector([selectFilters], (filters) => {
  return filters.reduce((total, filter) => {
    const activeOptionsCount = filter.options.filter((option) => option.active).length;

    total = total + activeOptionsCount;

    return total;
  }, 0);
});

export const selectTotalFiltersApplied = createSelector([selectFilters], (filters) => {
  const filtersWithActiveOptions = filters.filter((filter) => {
    return filter.options.some((option) => option.active);
  });

  return filtersWithActiveOptions.length;
});

export const selectUserAppliedFilterTitles = createSelector([selectFilters], (filters) => {
  const appliedFilterTitles = filters.reduce((acc: string[], filter) => {
    const filterIsApplied = filter.options.some((option) => option.active);

    if (filterIsApplied) {
      acc.push(filter.title);
    }

    return acc;
  }, []);

  return appliedFilterTitles.join(', ');
});
