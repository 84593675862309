import { selectRouterQueryParameters } from '@noths/polaris-client-next-redux';
import { selectFeatureFlags } from '@noths/polaris-client-user-configuration';
import { createSelector } from '@reduxjs/toolkit';
import type { ParsedUrlQueryInput } from 'querystring';

import { FilterUid } from 'src/components/organisms/Filter/constants/uids';
import { BrowseProvider } from 'src/constants/featureFlags';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';

export const selectFiltersWithActiveOptions = (state: ReduxApplicationState) => {
  return state.filter.collections.filters.filter((filter) => {
    return filter.options.some((option) => option.active);
  });
};

export const selectFiltersAsQueryObject = (state: ReduxApplicationState) => {
  const filtersAndSortWithActiveOptions = selectFiltersWithActiveOptions(state);
  const filtersToIncludeInQuery = filtersAndSortWithActiveOptions.filter(
    (filter) => filter.uid !== FilterUid.Sort,
  );

  return filtersToIncludeInQuery.reduce((queryObj, filter) => {
    const optionsHaveUIDs = filter.options.some((option) => option.uid !== null);

    if (optionsHaveUIDs) {
      const activeOptionValues = filter.options.filter((option) => option.active);
      const activeOptionsObj = activeOptionValues.reduce((obj, option) => {
        return {
          ...obj,
          [option.uid!]: option.value,
        };
      }, {});

      return {
        ...queryObj,
        ...activeOptionsObj,
      };
    }

    return {
      ...queryObj,
      [filter.uid]: filter.options.filter((option) => option.active).map((option) => option.value),
    };
  }, {});
};

export const selectRequestOptionsAsQueryObject = (
  state: ReduxApplicationState,
): ParsedUrlQueryInput => {
  const {
    filterMenu: { selectedSortOption },
    products: { searchTerm },
  } = state;

  const queryObject = selectFiltersAsQueryObject(state);

  const { previewing } = selectRouterQueryParameters(state);

  if (previewing) {
    queryObject['previewing'] = true;
  }

  if (selectedSortOption) {
    queryObject['sort'] = selectedSortOption.id;
  }

  if (searchTerm) {
    queryObject['term'] = searchTerm;
  }

  return queryObject;
};

export const selectCurrentPage = (state: ReduxApplicationState) => state.products.currentPage;

export const selectTotalPages = (state: ReduxApplicationState) => state.products.totalPages;

export const selectTotalProducts = (state: ReduxApplicationState) => state.products.totalProducts;

export const selectProductsByPage = (state: ReduxApplicationState) => state.products.items;

export const selectNextPageNumber = ({ products }: ReduxApplicationState) =>
  products.currentPage + 1;

export const selectPreviousPageNumber = ({ products }: ReduxApplicationState) =>
  parseInt(Object.keys(products.items)[0]) - 1;

export const selectPartnerShortcode = ({ products }: ReduxApplicationState) =>
  products.partner?.shortcode ?? '';

export const selectPartnerName = ({ products }: ReduxApplicationState) =>
  products.partner?.name ?? '';

export const selectPartnerMetaTagDescription = ({ products }: ReduxApplicationState) =>
  products.partner?.metaTagDescription ?? '';

export const selectPartnerLogoUrl = ({ products }: ReduxApplicationState) =>
  products.partner?.logo ?? '';

const PARTNER_HOMEPAGE_LINK_INDEX = 0;
const PARTNER_PRODUCTS_LINK_INDEX = 1;

export const selectPartnerHomepageUrl = ({ products }: ReduxApplicationState) =>
  products.partner?.links[PARTNER_HOMEPAGE_LINK_INDEX]?.href ?? '';

export const selectPartnerHomepageRelativeUrl = ({ products }: ReduxApplicationState) =>
  products.partner?.links[PARTNER_HOMEPAGE_LINK_INDEX]?.rel ?? '';

export const selectPartnerProductsUrl = ({ products }: ReduxApplicationState) =>
  products.partner?.links[PARTNER_PRODUCTS_LINK_INDEX]?.href ?? '';

export const selectProductFromCode = createSelector(
  [
    ({ products }: ReduxApplicationState) => products.items,
    (_, productCode: number) => productCode,
  ],
  (products, productCode) =>
    Object.values(products)
      .flat()
      .find(({ code }) => code === productCode),
);

export const selectBrowseProviderFeatureFlag = (state: ReduxApplicationState) => {
  return selectFeatureFlags(state)?.[BrowseProvider.NAME];
};

export const selectProviderAttributionToken = ({ products }: ReduxApplicationState) =>
  products.providerAttributionToken;

export const selectProviderMetadata = ({ products }: ReduxApplicationState) =>
  products.providerMetadata;
