import type { Dictionary } from '@noths/polaris-dev-ts-recipes';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createAction, createSlice } from '@reduxjs/toolkit';

import type {
  ProductVisiblePayload,
  SectionVisiblePayload,
  VisibilityTrackingState,
} from './types';

export const initialState: Readonly<VisibilityTrackingState> = {
  productImpressionQueue: [],
  atLeastOneProductHasBecomeVisible: false,
};

const { actions: reducerActions, reducer } = createSlice({
  name: 'visibilityTracking',
  initialState,
  reducers: {
    clearProductImpressionQueue: (state) => {
      state.productImpressionQueue = [];
    },
    onProductVisible: (state, action: PayloadAction<ProductVisiblePayload>) => {
      const {
        payload: { productId },
      } = action;

      state.atLeastOneProductHasBecomeVisible = true;
      state.productImpressionQueue = [...state.productImpressionQueue, productId];
    },
  },
});

const getSectionVisibleActionType = (sectionName: string) =>
  `visibilityTracker/sectionVisible/${sectionName}`;

const sectionVisible = <TPayload extends Dictionary>(
  payload: SectionVisiblePayload<TPayload>,
): SectionVisibleReturn<TPayload> => {
  const actionType = getSectionVisibleActionType(payload.sectionName);
  const actionCreator = createAction<TPayload, typeof actionType>(actionType);

  return actionCreator(payload);
};

export type SectionVisibleReturn<TPayload extends Dictionary = Dictionary> = {
  payload: SectionVisiblePayload<TPayload>;
  type: string;
};

export type SectionVisibleAction = ReturnType<typeof sectionVisible>;

const actions = {
  ...reducerActions,
  sendProductImpressionBatch: createAction('visibilityTracking/sendProductImpressionBatch'),
  sectionVisible,
  getSectionVisibleActionType,
};

export { actions, reducer };
