import { createAction } from '@reduxjs/toolkit';

type ProductUrlType = NonNullable<string | null>;

interface ProductClickPayload {
  destinationUrl: ProductUrlType;
  linkOpensInSameWindow: boolean;
  productCode: number;
  productUrl: ProductUrlType;
}

export const productListClick = createAction<ProductClickPayload>('products/productListClick');
