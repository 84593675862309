import { createSlice } from '@reduxjs/toolkit';

import { loadPageOfProducts } from 'src/redux/products/thunks/loadPageOfProducts';
import { updateFilters } from 'src/redux/products/thunks/updateFilters';
import { getProductPageStartAndEndIndex } from 'src/utils/product';

export interface AccessibilityState {
  statusMessage: string;
}

export const initialState: AccessibilityState = {
  statusMessage: '',
};

export const { actions, reducer } = createSlice({
  name: 'accessibility',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadPageOfProducts.fulfilled, (state, { payload }) => {
        if (payload.data) {
          const { currentPage, perPage, products } = payload.data;
          const { end, start } = getProductPageStartAndEndIndex(
            perPage,
            currentPage,
            products.length,
          );

          state.statusMessage = `Products ${start} to ${end} loaded`;
        }
      })
      .addCase(loadPageOfProducts.pending, (state) => {
        state.statusMessage = 'Loading more products';
      })
      .addCase(loadPageOfProducts.rejected, (state) => {
        state.statusMessage = 'There was a problem loading more products';
      })
      .addCase(updateFilters.fulfilled, (state) => {
        state.statusMessage = `Products loaded`;
      })
      .addCase(updateFilters.pending, (state) => {
        state.statusMessage = 'Loading products';
      })
      .addCase(updateFilters.rejected, (state) => {
        state.statusMessage = 'There was a problem loading products';
      });
  },
});
