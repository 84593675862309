import type { BaseFieldObject } from '@noths/polaris-dev-ts-types';

type ReturnedProduct = {
  product: {
    id: string;
  };
};

/**
 * @schema https://github.com/notonthehighstreet/analytics-schema-definitions/blob/main/objects/impression-list-view.json
 */
export type ProductImpressionsTrackingEvent = {
  attribution_token?: string;
  ecommerce?: {
    impressions: BaseFieldObject[];
  };
  event: 'product_impressions';
  event_action: 'Product Impressions' | 'Load More Impressions' | 'Load Previous Impressions';
  event_category: 'Product listings' | 'Rich Relevance';
  event_label: string | null;
  returned_products?: ReturnedProduct[];
};

interface GetProductImpressionsTrackingEventArgs {
  attributionToken?: string;
  crsFilter?: string;
  eventAction: 'Product Impressions' | 'Load More Impressions' | 'Load Previous Impressions';
  eventCategory: 'Product listings' | 'Rich Relevance';
  eventLabel: string | null;
  impressions?: BaseFieldObject[];
  returnedProducts?: ReturnedProduct[];
}

export const getProductImpressionsTrackingEvent = ({
  attributionToken,
  crsFilter,
  eventAction,
  eventCategory,
  eventLabel,
  impressions,
  returnedProducts,
}: GetProductImpressionsTrackingEventArgs): ProductImpressionsTrackingEvent => {
  return {
    event: 'product_impressions',
    event_category: eventCategory,
    event_action: eventAction,
    event_label: eventLabel,
    ...(attributionToken && { attribution_token: attributionToken }),
    ...(crsFilter && { crs_filter: crsFilter }),
    ...(impressions && { ecommerce: { impressions } }),
    ...(returnedProducts && { returned_products: returnedProducts }),
  };
};
