import { createSlice } from '@reduxjs/toolkit';

export interface FilterModalState {
  isModalOpen: boolean;
}

const initialState: FilterModalState = {
  isModalOpen: false,
};

export const { actions, reducer } = createSlice({
  name: 'filterModal',
  initialState,
  reducers: {
    closeModal: (state: FilterModalState) => {
      state.isModalOpen = false;
    },
    openModal: (state: FilterModalState) => {
      state.isModalOpen = true;
    },
  },
});
