/* istanbul ignore file */
import { generateStore, withStateHydration } from '@noths/polaris-client-next-redux';
import type { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';

import type { ReduxApplicationState } from './combinedReducer';
import { combinedReducer } from './combinedReducer';
import { GAMiddleware } from './GAMiddleware';

export const { getStore, useStore } = generateStore<ReduxApplicationState>({
  reducer: withStateHydration<ReduxApplicationState>(combinedReducer),
  middleware: [GAMiddleware],
});

export type AppThunkDispatch = ThunkDispatch<void, ReduxApplicationState, AnyAction>;
