import { getProductIdFromProductCode } from '@noths/polaris-client-utils';

import type { BrowseDataAPIProduct } from 'src/services/browse-data-api/types/BrowseDataAPIProduct';

export const transformProductsToGAReturnedProductsArray = (products: BrowseDataAPIProduct[]) => {
  return products.map(({ code }) => ({
    product: {
      id: String(getProductIdFromProductCode(code)),
    },
  }));
};
