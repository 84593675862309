import type {
  BrowseDataAPIFilterOption,
  FilterOptionValue,
} from 'src/services/browse-data-api/types/BrowseDataAPIFilter';

interface FilterOptionUidValuePairs {
  uid: string;
  value: BrowseDataAPIFilterOption['value'];
}

export const setOptionsValues = (
  filterOptions: BrowseDataAPIFilterOption[],
  optionUidValuePairs: FilterOptionUidValuePairs[],
) => {
  return filterOptions.map((option) => {
    const newOptionState = optionUidValuePairs.find(({ uid }) => option.uid === uid);

    if (newOptionState) {
      return {
        ...option,
        active: newOptionState.value !== null,
        value: newOptionState.value,
      };
    }

    return option;
  });
};

export const setOptionActiveByValue = (
  filterOptions: BrowseDataAPIFilterOption[],
  value: FilterOptionValue,
  active: boolean,
): BrowseDataAPIFilterOption[] => {
  return filterOptions.map((option) => {
    if (option.value === value) {
      return {
        ...option,
        active,
      };
    }

    return option;
  });
};

export const setOptionToActiveRemoveOthers = (
  filterOptions: BrowseDataAPIFilterOption[],
  value: FilterOptionValue,
): BrowseDataAPIFilterOption[] => {
  const activatedOption = filterOptions.find((option) => option.value === value)!;

  return [{ ...activatedOption, active: true }];
};

export const setOptionToActiveSetOthersToInactive = (
  filterOptions: BrowseDataAPIFilterOption[],
  value: FilterOptionValue,
) => {
  return filterOptions.map((option) => {
    return {
      ...option,
      active: option.value === value,
    };
  });
};
