import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { FilterUid } from 'src/components/organisms/Filter/constants/uids';
import type { ChangedFilterDetails } from 'src/components/organisms/Filter/types';
import { actions as filterMenuActions } from 'src/components/organisms/FilterMenu/modules/slice';
import { requestProducts } from 'src/redux/products/thunks/requestProducts';
import type {
  BrowseDataAPIFilter,
  FilterOptionValue,
} from 'src/services/browse-data-api/types/BrowseDataAPIFilter';
import {
  setOptionActiveByValue,
  setOptionsValues,
  setOptionToActiveRemoveOthers,
  setOptionToActiveSetOthersToInactive,
} from './helpers/filterOptionHelpers';
import { getFiltersWithSortFilter } from './helpers/getFiltersWithSortFilter';

interface LastUpdatedFilter {
  filterOptionActive: boolean;
  filterOptionValue: FilterOptionValue;
  filterUid: string;
}

export interface FilterState {
  filters: BrowseDataAPIFilter[];
  lastUpdatedFilter: LastUpdatedFilter | null;
}

export const initialState: Readonly<FilterState> = {
  filters: [],
  lastUpdatedFilter: null,
};

export const { actions, reducer } = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    changeFilterOption: (state, action: PayloadAction<ChangedFilterDetails>) => {
      const changedFilter = action.payload;

      state.filters = state.filters.map((filter) => {
        if (filter.uid !== changedFilter.uid) {
          return filter;
        }

        if (filter.uid === FilterUid.Price) {
          const changedOptionUidValuePairs = changedFilter.options.map((changedOption) => ({
            uid: changedOption.uid!,
            value: changedOption.value,
          }));

          return {
            ...filter,
            options: setOptionsValues(filter.options, changedOptionUidValuePairs),
          };
        }

        const { active, value } = changedFilter.options[0];

        switch (filter.type) {
          case 'tree':
            return {
              ...filter,
              options: setOptionToActiveRemoveOthers(filter.options, value),
            };
          case 'radio':
            return {
              ...filter,
              options: setOptionToActiveSetOthersToInactive(filter.options, value),
            };
          case 'checkbox':
            return {
              ...filter,
              options: setOptionActiveByValue(filter.options, value, active),
            };
          default:
            return filter;
        }
      });

      if (changedFilter.uid !== FilterUid.Sort && changedFilter.uid !== FilterUid.Price) {
        state.lastUpdatedFilter = {
          filterOptionActive: changedFilter.options[0].active,
          filterOptionValue: changedFilter.options[0].value,
          filterUid: changedFilter.uid,
        };
      } else {
        state.lastUpdatedFilter = null;
      }
    },
    resetFilters: (state) => {
      state.filters = state.filters.map((filter) => {
        const hasActiveOption = filter.options.some((option) => option.active);

        if (hasActiveOption) {
          return {
            ...filter,
            options: filter.options.map((option) => {
              if (option.active) {
                return {
                  ...option,
                  value: filter.type === 'range' ? null : option.value,
                  active: false,
                };
              }

              return option;
            }),
          };
        }

        return filter;
      });

      state.lastUpdatedFilter = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestProducts.fulfilled, (state, { payload }) => {
        if (payload.data?.filters) {
          state.filters = getFiltersWithSortFilter(
            payload.data.filters,
            payload.data.sortingOptions,
          );
        }
      })
      .addCase(filterMenuActions.selectSortOption, (state) => {
        state.lastUpdatedFilter = null;
      });
  },
});
