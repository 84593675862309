export enum FilterUid {
  Categories = 'filter[category_id]',
  Colour = 'filter[attribute_values][12][]',
  Delivery = 'filter[delivery][]',
  DeliveryZones = 'filter[delivery_zone]',
  Discount = 'filter[discount][]',
  Personalisation = 'filter[personalisable]',
  Price = 'price',
  Sort = 'filter[sort]',
  SpecialFeatures = 'filter[special][]',
  Subcategories = 'filter[subcategories][]',
}
