import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type {
  SelectedSortOption,
  SortOptionValue,
} from 'src/components/organisms/FilterMenu/types';
import { requestProducts } from 'src/redux/products/thunks/requestProducts';
import type { BrowseDataAPISortingOption } from 'src/services/browse-data-api/types/BrowseDataAPISortingOption';
import { getSelectedSortOption } from './reducerHelpers/getSelectedSortOption';

export interface FilterMenuState {
  selectedSortOption: SelectedSortOption;
  sortingOptions: BrowseDataAPISortingOption[];
}

export const initialState: FilterMenuState = {
  sortingOptions: [],
  selectedSortOption: null,
};

export const { actions, reducer } = createSlice({
  name: 'filterMenu',
  initialState,
  reducers: {
    selectSortOption: (state, action: PayloadAction<SortOptionValue>) => {
      state.selectedSortOption =
        state.sortingOptions.find((option) => option.id === action.payload) || null;
    },
    resetSelectedSortOption: (state: FilterMenuState) => {
      state.selectedSortOption = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestProducts.fulfilled, (state, { payload }) => {
      if (payload.data?.sortingOptions) {
        state.sortingOptions = payload.data.sortingOptions;
        state.selectedSortOption = getSelectedSortOption(payload.data.sortingOptions);
      }
    });
  },
});
